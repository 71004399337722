import React, { Fragment, memo, useMemo, useState } from "react";
import {
  Modal,
  Button,
  Carousel,
  Card,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";

const OrderModal = ({
  show,
  order,
  status: statusBase,
  onClose,
  onActionClick,
}) => {
  //console.log("order: ", order);
  const [loadingAction, setLoadingAction] = useState(false);

  const status = useMemo(
    () =>
      statusBase || {
        color: "primary",
        text: "Iniciar",
        cursor: false,
      },
    [statusBase]
  );

  // Handles
  const handleClose = () => {
    onClose && onClose();
  };

  const handleActionClick = async (event) => {
    setLoadingAction(true);
    onActionClick && (await onActionClick(event));
    setLoadingAction(false);
  };

  return (
    order && (
      <Modal show={show} onHide={handleClose} fullscreen="true" size="xl">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 20 }}>
            <span style={{ marginRight: 16 }}>
              {" "}
              {order.orderNumber && "#" + order.orderNumber}
              {" - "}
              {order.billingPerson && order.billingPerson.name}
            </span>
            {/*<Button
              variant="secondary"
              onClick={handleClose}
              style={{ padding: 12, fontSize: 14}}
            >
              X
            </Button>*/}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {onActionClick ? (
            <Button
              variant={status.color}
              size="sm"
              onClick={handleActionClick}
              style={{
                cursor: status.cursor ? "pointer" : "initial",
                padding: 12,
                fontSize: 12,
              }}
            >
              {loadingAction ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                status.text
              )}
            </Button>
          ) : (
            <></>
          )}
          {/*<Carousel>
            {(order.items || []).map(
              ({ name, shortDescription, imageUrl }, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-80"
                      src={imageUrl}
                      alt={name}
                      style={{ width: 275, height: 250, margin: "auto" }}
                    />
                    <Caption>
                      <h6>{name}</h6>
                      <p>{shortDescription}</p>
                    </Caption>
                  </Carousel.Item>
                );
              }
            )}
          </Carousel>*/}

          {(order.items || []).map((item, index) => {
            return (
              <Card style={{ marginTop: 8 }} key={index}>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <Fragment>
                          <img
                            className="d-block w-80"
                            src={item.imageUrl}
                            alt={item.name}
                            style={{ width: 200, height: 200 }}
                          />
                        </Fragment>
                      </Col>
                      <Col xs={12} md={6}>
                        <Fragment>
                          <h5>{item.name}</h5>
                          <div style={{ fontSize: 14 }}>
                            <div key={item.id}>
                              <strong>{item.quantity} </strong> x {item.name}
                              {item.selectedOptions &&
                                item.selectedOptions.length && (
                                  <UIStyle>
                                    {item.selectedOptions.map(
                                      (option, indexOption) => (
                                        <li key={indexOption}>
                                          {option.name}: {option.value}
                                        </li>
                                      )
                                    )}
                                  </UIStyle>
                                )}
                            </div>
                          </div>
                        </Fragment>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            );
          })}

          {/* Footer */}
          <Card style={{ marginTop: 8 }}>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    {order.shippingPerson && (
                      <Fragment>
                        <h5 style={{ marginLeft: 24 }}>Dirección de envío</h5>
                        <div style={{ fontSize: 14 }}>
                          <ul>
                            {order.orderType &&
                              order.orderType === "pickup" && (
                                <li>{"Pickup"}</li>
                              )}
                            <li>{order.shippingPerson.name}</li>
                            {order.shippingPerson &&
                              order.shippingPerson.companyName && (
                                <li>{order.shippingPerson.companyName}</li>
                              )}
                            {order.shippingPerson &&
                              order.shippingPerson.phone && (
                                <li>{order.shippingPerson.phone}</li>
                              )}
                            {order && order.email && <li>{order.email}</li>}
                            {order.shippingPerson &&
                              order.shippingPerson.street && (
                                <li>Lugar: {order.shippingPerson.street}</li>
                              )}
                          </ul>
                        </div>
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>

          {/*<Card style={{ marginTop: 8 }}>
            <Card.Body>
              <Container>
                <Row>
                  <Col xs={12} md={6}>
                    <Fragment>
                      <h5>Orden</h5>
                      <div style={{ fontSize: 14 }}>
                        <ItemsNumber>
                          {order.items.length} Artículos
                        </ItemsNumber>
                        {order.items.map((item) => (
                          <div key={item.id}>
                            <strong>{item.quantity} </strong> x {item.name}
                            {item.selectedOptions &&
                              item.selectedOptions.length && (
                                <UIStyle>
                                  {item.selectedOptions.map(
                                    (option, indexOption) => (
                                      <li key={indexOption}>
                                        {option.name}: {option.value}
                                      </li>
                                    )
                                  )}
                                </UIStyle>
                              )}
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  </Col>

                  <ColShipping xs={16} md={6}>
                    {order.shippingPerson && (
                      <Fragment>
                        <h5 style={{ marginLeft: 24 }}>Dirección de envío</h5>
                        <div style={{ fontSize: 14 }}>
                          <ul>
                            {order.orderType &&
                              order.orderType === "pickup" && (
                                <li>{"Pickup"}</li>
                              )}
                            <li>{order.shippingPerson.name}</li>
                            {order.shippingPerson &&
                              order.shippingPerson.companyName && (
                                <li>{order.shippingPerson.companyName}</li>
                              )}
                            {order.shippingPerson &&
                              order.shippingPerson.phone && (
                                <li>{order.shippingPerson.phone}</li>
                              )}
                            {order && order.email && <li>{order.email}</li>}
                            {order.shippingPerson &&
                              order.shippingPerson.street && (
                                <li>Lugar: {order.shippingPerson.street}</li>
                              )}
                             {order.shippingPerson &&
                              order.shippingPerson.street && (
                                <li>{order.shippingPerson.city}</li>
                              )} 
                          </ul>
                        </div>
                      </Fragment>
                    )}
                  </ColShipping>
                </Row>
              </Container>
            </Card.Body>
          </Card>*/}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ padding: 12, fontSize: 14 }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

const Caption = styled(Carousel.Caption)`
  background: black;
  opacity: 0.6;
`;
const ColShipping = styled(Col)`
  @media (max-width: 767px) {
    padding-top: 1rem;
  }
`;

const ItemsNumber = styled.div`
  color: gray;
  font-size: 14px;
`;

const UIStyle = styled.ul`
  margin: 0;
`;

export default OrderModal;
