//import _ from "lodash";
import moment from "moment";

const getIsToday = (date) => {
  date = new Date(date);
  date = moment(date).format("YYYY-MM-DD");
  const year = date.substr(0, 4);
  const month = date.substr(5, 2);
  const day = date.substr(8, 2);

  //const today = new Date(); //Como lo tenian
  //date = moment(date).toDate();

  //return (
  //  Number(day) === Number(today.getDate()) &&
  //  Number(month) === Number(today.getMonth() + 1) &&
  //  Number(year) === Number(today.getFullYear())
  //);

  // Implementación de DatePicker
  //const localDay = JSON.parse(window.localStorage.getItem("date"));
  const localDay = window.sessionStorage.getItem("date");
  let yearD = "";
  let monthD = "";
  let dayD = "";
  if (localDay) {
    //yearD = localDay.substr(1, 4);
    //monthD = localDay.substr(6, 2);
    //dayD = localDay.substr(9, 2);
    yearD = localDay.slice(1, 5);
    monthD = localDay.slice(6, 8);
    dayD = localDay.slice(9, 11);
  } else {
    const today = new Date();
    dayD = today.getDate();
    monthD = today.getMonth() + 1;
    yearD = today.getFullYear();
  }

  return (
    Number(day) === Number(dayD) &&
    Number(month) === Number(monthD) &&
    Number(year) === Number(yearD)
  );
};

const getOrderDeliveryStartHour = (date) => {
  const hour = Number(date.substr(11, 2));
  const hourStr = hour.toString();
  const min = date.substr(13, 3);
  return hourStr + min;

  //return moment(date).local().format("HH:mm");
};

const getOrderDeliveryEndHour = (date) => {
  const hour = Number(date.substr(11, 2) - 6);
  const hourStr = hour.toString();
  const min = date.substr(13, 3);
  return hourStr + min;

  //return moment(date).local().format("HH:mm");
};

const getOrderType = (item) => {
  if (
    item.extraFields &&
    item.extraFields.ecwid_order_delivery_time_interval_start
  ) {
    return "delivery";
  }
  // else if (item.extraFields && item.extraFields.ecwid_order_pickup_time) {
  //   return "pickup";}
  else if (
    item.paymentMethod &&
    item.paymentMethod === "Sell on the Go - Cash"
  ) {
    return "pickup";
  }
  //else if (item.paymentMethod && item.paymentMethod == "Código de Cortesía") {
  //   return "pickup";
  else if (item && item.pickupTime) {
    return "delivery";
  }
};

const getOrderStartDate = (item) => {
  let start =
    (item.extraFields &&
      item.extraFields.ecwid_order_delivery_time_interval_start) ||
    (item.extraFields && item.extraFields.ecwid_order_pickup_time) ||
    null;
  return start;
};

const getOrderEndDate = (item) => {
  let end =
    (item.extraFields &&
      item.extraFields.ecwid_order_delivery_time_interval_end) ||
    (item.extraFields && item.extraFields.ecwid_order_pickup_time) ||
    null;
  return end;
};

const getLocation = (item) => {
  if (!item.billingPerson.street) {
    return;
  }
  let ubicacionFilter = item.billingPerson.street.split("\n");
  if (ubicacionFilter.length === 1) {
    return "-";
  }
  return `-${ubicacionFilter[1]}`;
};

const groupFormat = (item, keyGroup, startHour, endHour, name, orderType) => {
  return {
    id: keyGroup,
    title: `Órdenes`,
    orderType,
    startHour: startHour,
    endHour: endHour,
    name: name,
    cards: [],
  };
};

const generateDataOrder = (item) => {
  //let location = getLocation(item);
  let orderType = getOrderType(item) || "other";
  let startDate = getOrderStartDate(item) || null;
  let endDate = getOrderEndDate(item) || null;
  startDate = new Date(startDate);
  startDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss Z");
  let startHour = (startDate && getOrderDeliveryStartHour(startDate)) || null;
  let endHour = (endDate && getOrderDeliveryEndHour(endDate)) || null;
  let keyGroup =
    startHour && endHour ? Number(`${startHour.replace(":", "")}`) : "other";
  //startHour && endHour ? `${startHour.replace(":", "")}${location}` : "other";
  let name = "Órdenes";
  if (orderType === "delivery") {
    //name = `${startHour}${location}`;
    name = `${startHour}`;
  } else if (orderType === "pickup") {
    name = "Punto de venta";
  } else if (orderType === "Efectivo") {
    name = "Órdenes";
  } else if (orderType === "Código de Cortesía") {
    name = "Órdenes";
  }

  let initialOrderFormat = groupFormat(
    item,
    keyGroup,
    startHour,
    endHour,
    name,
    orderType
  );

  return {
    keyGroup,
    startDate,
    endDate,
    startHour,
    endHour,
    orderType,
    name,
    initialOrderFormat,
  };
};

const sortObject = (o) => {
  //Como estaba
  //let orderObject =Object.keys(o)
  //  .sort()
  //  .reduce((r, k) => ((r[k] = o[k]), r), {})

  let orderObject = Object.keys(o)
    .sort()
    .reduce((object, key) => {
      if (key === "other") {
        object[0] = o[key];
      } else {
        object[key] = o[key];
      }
      return object;
    }, {});

  return orderObject;
};

export default (items, showCompletes) => {
  let hourGroupArray = {};
  let isToday;

  for (let item of items) {
    let isDelivered = false;
    if (item && item.pickupTime) {
      isToday = getIsToday(item.pickupTime);
    } else if (item && item.createDate) {
      isToday = getIsToday(item.createDate);
    }
    // } else if (
    //   item &&
    //   item.extraFields &&
    //   item.extraFields.ecwid_order_delivery_time_interval_start
    // ) {
    //   isToday = getIsToday(
    //     item.extraFields.ecwid_order_delivery_time_interval_start
    //   );
    // } else if (
    //   item &&
    //   item.extraFields &&
    //   item.extraFields.ecwid_order_pickup_time
    // ) {
    //   isToday = getIsToday(item.extraFields.ecwid_order_pickup_time);
    // } else if (item && item.createDate) {
    //   isToday = getIsToday(item.createDate);
    // }
    if (!showCompletes && item.fulfillmentStatus === "DELIVERED") {
      isDelivered = true;
    }
    if (!showCompletes && item.fulfillmentStatus === "OUT_FOR_DELIVERY") {
      isDelivered = true;
    }
    if (!showCompletes && item.fulfillmentStatus === "READY_FOR_PICKUP") {
      isDelivered = true;
    }

    if (
      isToday &&
      item.paymentMethod === "Sell on the Go - Cash" &&
      isDelivered
    ) {
      let { keyGroup, initialOrderFormat, orderType } = generateDataOrder(item);

      hourGroupArray[keyGroup] = hourGroupArray[keyGroup] || initialOrderFormat;

      hourGroupArray[keyGroup].cards.push({ ...item, orderType });
    }

    if (isToday && !isDelivered) {
      let { keyGroup, initialOrderFormat, orderType } = generateDataOrder(item);

      hourGroupArray[keyGroup] = hourGroupArray[keyGroup] || initialOrderFormat;

      hourGroupArray[keyGroup].cards.push({ ...item, orderType });
    }
  }

  hourGroupArray = sortObject(hourGroupArray);

  return Object.values(hourGroupArray);
};
