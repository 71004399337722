import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Spinner } from "react-bootstrap";
import firebase from "../../util/firebase";
import { useAuth } from "../../util/auth.js";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const db = firebase.firestore();

const OffersRenderHeader = ({ group: { id, title, cards }, onRemoveGroup }) => {
  const auth = useAuth();
  const { addToast } = useToasts();

  // State
  const [loading, setLoading] = useState(false);
  const [piso, setPiso] = useState("");
  const [torre, setTorre] = useState("");
  const [hora, setHora] = useState("");

  // Handles
  const handleAcceptClick = async () => {
    try {
      setLoading(true);
      const offerRef = await db.collection("offers").doc(id);
      await offerRef.set(
        {
          acceptedAt: (auth && auth.user && auth.user.id) || null,
          updatedAt: new Date(),
        },
        { merge: true }
      );
      addToast("Oferta aceptada", { appearance: "success", autoDismiss: true });

      onRemoveGroup && onRemoveGroup();
    } catch (err) {
      addToast("Oferta no aceptada", {
        appearance: "error",
        autoDismiss: true,
      });
      console.error("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  //Card title
  useEffect(() => {
    getHeaderEcwid(cards[0].billingPerson.street, cards[0].pickupTime);
  }, []);

  //Funtions
  const getHeaderEcwid = (domicilio, hora) => {
    let arregloPiso = domicilio.split("\n");
    if (arregloPiso.length === 1) {
      return;
    }
    arregloPiso = arregloPiso[1].split(",");
    let piso = arregloPiso[0];
    let torre = arregloPiso[1].slice(1);
    let formatHora = moment(hora).format("HH:mm");

    setTorre(torre);
    setPiso(piso);
    setHora(formatHora);
    return;
  };

  return (
    <>
      <HeaderColumn>
        <span>{/*title*/} </span>
        <div>
          Torre: {torre}
          <p style={{ marginBottom: 0 }}>Piso: {piso}</p>
          {hora}
        </div>
        <div className="px-1">
          <Button variant="warning" size="sm" onClick={handleAcceptClick}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Aceptar oferta"
            )}
          </Button>
        </div>
      </HeaderColumn>
    </>
  );
};

const HeaderColumn = styled.div`
  font-size: 15px;
  color: black;
  font-weight: normal;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export default OffersRenderHeader;
