import { it } from "date-fns/locale";
import React, { memo, useMemo } from "react";
import ecwid from "../../ecwid";
import { useRouter } from "../../util/router.js";
import OrderCard from "../OrderCard";

const RenderCard = memo(({ order, onUpdateCard }) => {
  const router = useRouter();

  // Effects
  // useEffect(() => {
  //   console.debug("RenderCard render", order.id);
  // });

  const getStatusDelivery = (fulfillmentStatus, status) => {
    switch (fulfillmentStatus) {
      case "AWAITING_PROCESSING":
        status.color = "primary";
        status.text = "Iniciar pedido";
        status.cursor = true;
        break;

      case "PROCESSING":
        status.color = "warning";
        status.text = "Listo para entregar";
        status.cursor = true;
        break;

      case "OUT_FOR_DELIVERY":
        if (!order.additionalInfo.shipmentId) {
          status.color = "success";
          status.text = "Listo para entregar";
          status.cursor = false;
        } else {
          status.color = "secondary";
          status.text = "En proceso de envío";
          status.cursor = false;
        }
        break;

      case "SHIPPED":
        status.color = "secondary";
        status.text = "En camino";
        status.cursor = true;
        break;

      case "DELIVERED":
        status.color = "secondary";
        status.text = "Entregado";
        status.cursor = true;
        break;

      default:
        status.color = "info";
        status.text = "Inválido";
        status.cursor = false;
        break;
    }
    return status;
  };

  const getStatusPickup = (fulfillmentStatus, status) => {
    switch (fulfillmentStatus) {
      case "AWAITING_PROCESSING":
        status.color = "danger";
        status.text = "Iniciar pedido";
        status.cursor = true;
        break;

      case "PROCESSING":
        status.color = "warning";
        status.text = "Listo para recoger";
        status.cursor = true;
        break;

      case "OUT_FOR_DELIVERY":
        if (!order.additionalInfo.shipmentId) {
          status.color = "primary";
          status.text = "Entregar";
          status.cursor = true;
        } else {
          status.color = "secondary";
          status.text = "En proceso de envío";
          status.cursor = false;
        }
        break;

      case "READY_FOR_PICKUP":
        if (!order.additionalInfo.shipmentId) {
          status.color = "primary";
          status.text = "Entregar";
          status.cursor = true;
        } else {
          status.color = "secondary";
          status.text = "En proceso de envío";
          status.cursor = false;
        }
        break;

      case "SHIPPED":
        status.color = "secondary";
        status.text = "En camino";
        status.cursor = true;
        break;

      case "DELIVERED":
        status.color = "secondary";
        status.text = "Entregado";
        status.cursor = true;
        break;

      default:
        status.color = "info";
        status.text = "Inválido";
        status.cursor = false;
        break;
    }
    return status;
  };

  const status = useMemo(() => {
    if (order) {
      let status = { cursor: true };
      if (order.orderType === "delivery") {
        status = getStatusDelivery(order.fulfillmentStatus, status);
      } else if (
        order.orderType === "pickup" ||
        order.paymentStatus === "Efectivo"
      ) {
        status = getStatusPickup(order.fulfillmentStatus, status);
      }
      return status;
    }
    return null;
  }, [order]);

  const changeStatusDelivery = async (event) => {
    if (
      status &&
      status.cursor &&
      ["AWAITING_PROCESSING", "PROCESSING"].includes(order.fulfillmentStatus)
    ) {
      try {
        event.stopPropagation();

        const fulfillmentStatus =
          order.fulfillmentStatus === "AWAITING_PROCESSING"
            ? "PROCESSING"
            : "OUT_FOR_DELIVERY";

        // await new Promise((resolve) => setTimeout(resolve, 5000));
        await ecwid.updateOrder(order.orderNumber, {
          fulfillmentStatus,
        });
        onUpdateCard && onUpdateCard({ ...order, fulfillmentStatus });
        //Hacer que funcione cuando le des click a crear oferta
        //if(fulfillmentStatus === 'OUT_FOR_DELIVERY'){
        //  window.location.reload();
        //}
      } catch (err) {
        console.error("Error ::>", err);
      }
    } else if (order.fulfillmentStatus === "SHIPPED") {
      event.stopPropagation();
      router.push(`/tracking/${order.id}`);
    }
  };

  const changeStatusPickup = async (event) => {
    if (
      status &&
      status.cursor &&
      ["AWAITING_PROCESSING", "PROCESSING"].includes(
        order.fulfillmentStatus
      )
    ) {
      try {
        event.stopPropagation();
        let fulfillmentStatus, paymentStatus;
        fulfillmentStatus =
          order.fulfillmentStatus === "AWAITING_PROCESSING"
            ? "PROCESSING"
            : "OUT_FOR_DELIVERY";

        if (order.fulfillmentStatus === "OUT_FOR_DELIVERY") {
          fulfillmentStatus = "DELIVERED";
          if (
            order.discountCoupon &&
            order.discountCoupon.orderCount === 0 &&
            order.paymentStatus === "AWAITING_PAYMENT"
          ) {
            paymentStatus = order.paymentStatus = "PAID";
          }
        }

        // await new Promise((resolve) => setTimeout(resolve, 5000));
        await ecwid.updateOrder(order.orderNumber, {
          fulfillmentStatus,
          paymentStatus,
        });
        onUpdateCard && onUpdateCard({ ...order, fulfillmentStatus });
        if(fulfillmentStatus === 'OUT_FOR_DELIVERY'){
          window.location.reload();
        }
      } catch (err) {
        console.error("Error ::>", err);
      }
    }
  };

  // Handles
  const handleActionClick = async (event) => {
    if (order && order.orderType === "delivery") {
      changeStatusDelivery(event);
    } else if (order && order.orderType === "pickup") {
      changeStatusPickup(event);
    }
  };

  return (
    <OrderCard
      order={order}
      status={status}
      onActionClick={handleActionClick}
    />
  );
});

export default RenderCard;
