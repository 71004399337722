import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Section from "../Section";
import { Container, Image } from "react-bootstrap";
import firebase from "../../util/firebase";
import ecwid from "../../ecwid";
import { optimizeOffer } from "../../services/RouteOfferOptimization";
import OrderDelivered from "../../assets/images/Bambu-Lomas-Logo.png";
import RenderCard from "./OffersRenderCard";
import RenderHeader from "./OffersRenderHeader";
import PageLoader from "../PageLoader";
import Kanban from "../Kanban";

const Offers = (props) => {
  const db = firebase.firestore();

  // States
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Functions
  const getOffers = useCallback(async () => {
    setLoading(true);
    const offers = await db
      .collection("offers")
      .where("acceptedAt", "==", null)
      .orderBy("updatedAt")
      .get();

    let queuedTasksAsync = await Promise.all(
      offers.docs.map(async (doc, index) => {
        const { orders, ...otherData } = doc.data();
        let cards = await Promise.all(
          (orders || []).map(async (orderNumber) => {
            const order = await ecwid.getOrderDetails(orderNumber);
            //console.log(order)
            return order;
          })
        );

        const res = await optimizeOffer({ orders: cards });
        if (
          res &&
          res.waypointOrder &&
          res.waypointOrder.length === cards.length &&
          res.waypoints &&
          res.waypoints.length === cards.length
        ) {
          cards = res.waypointOrder.map((index) => cards[index]);
        }

        return {
          ...otherData,
          id: doc.id,
          title: `Oferta ${index + 1}`,
          cards: cards,
        };
      })
    );

    // console.debug("queuedTasksAsync", queuedTasksAsync.length);
    setOffers(queuedTasksAsync);
    setLoading(false);
  }, [db]);

  useEffect(() => {
    const eventsRef = db.collection("offers");

    var unsubscribe = eventsRef
      .where("acceptedAt", "==", null)
      .onSnapshot((datos) => {
        //setLoading(true);
        getOffers();
      });

    return unsubscribe;
  }, []);

  // Effects
  useEffect(() => {
    // console.count("useEffect getOffers");
    getOffers();
  }, [getOffers]);

  return loading ? (
    <PageLoader />
  ) : (
    <Section
      py="1"
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container fluid>
        {/* <pre>{JSON.stringify({ columns: offers }, null, 2)}</pre> */}
        {offers && offers.length ? (
          <Kanban
            groups={offers}
            renderColumnHeader={(group, { removeGroup }) => (
              <RenderHeader group={group} onRemoveGroup={removeGroup} />
            )}
            renderCard={(card, _) => <RenderCard order={card} />}
          />
        ) : (
          <WrapperImage>
            <Image src={OrderDelivered} fluid={true} />
            <figcaption>
              {/* No existen ofertas, <Link to="/dashboard">ver órdenes</Link>. */}
            </figcaption>
          </WrapperImage>
        )}
      </Container>
    </Section>
  );
};

const WrapperImage = styled.figure`
  width: 16%;
  margin: 0 auto;
  margin-top: 8rem;
`;

export default Offers;
