import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Spinner } from "react-bootstrap";
import ecwid from "../../ecwid";
import firebase from "../../util/firebase";
import { useToasts } from "react-toast-notifications";

const db = firebase.firestore();
const OrderRenderHeader = ({ group, onUpdateGroup }) => {
  const now = new Date().getHours();
  const { addToast } = useToasts();
  // States
  const [ordersToShip, setOrdersToShip] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handles
  const handleCreateOfferClick = async () => {
    let arrayOrderToShip = [];
    ordersToShip.map((order) => {
      if (order.billingPerson.street.length) {
        let arregloPiso = order.billingPerson.street.split("\n");
        arregloPiso = arregloPiso[1];
        arregloPiso = arregloPiso.split(",");
        arregloPiso = arregloPiso[1].slice(1).toString();

        if (arrayOrderToShip[arregloPiso]) {
          arrayOrderToShip[arregloPiso].push(order.id);
        } else {
          arrayOrderToShip[arregloPiso] = [order.id];
        }
      }
    });

    try {
      setLoading(true);
      let arrayKeys = Object.keys(arrayOrderToShip);

      for (let key of arrayKeys) {
        // console.debug("handleCreateOfferClick", ordersToShip);
        const result = await db.collection("offers").add({
          status: "OUT_FOR_DELIVERY",
          orders: arrayOrderToShip[key].map((orderNumber) =>
            parseInt(orderNumber)
          ),
          createdAt: new Date(),
          updatedAt: new Date(),
          acceptedAt: null,
        });
        if (result && result.id) {
          // const queuedTasksAsync =
          await Promise.all(
            ordersToShip.map(async ({ orderNumber }) => {
              return await ecwid.updateOrder(orderNumber, {
                additionalInfo: { shipmentId: result.id },
              });
            })
          );
          // console.debug("queuedTasksAsync", queuedTasksAsync.length);
          addToast("Oferta creada", {
            appearance: "success",
            autoDismiss: true,
          });
          const { cards, ...propsGroup } = group;
          cards.forEach((card) => {
            const orderToShip = ordersToShip.find(({ id }) => id === card.id);
            if (orderToShip) {
              card.additionalInfo.shipmentId = result.id;
            }
          });
          onUpdateGroup && onUpdateGroup({ ...propsGroup, cards });
          window.location.reload(true);
        } else {
          addToast("Oferta no creada", {
            appearance: "info",
            autoDismiss: true,
          });
        }
      }
    } catch (err) {
      addToast("Oferta no creada", { appearance: "error", autoDismiss: true });
      console.error("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    // console.debug("useEffect group");
    if (group && group.cards && group.cards.length) {
      const ordersToShip = ((group && group.cards) || []).filter(
        (card) =>
          card.fulfillmentStatus === "OUT_FOR_DELIVERY" &&
          !card.additionalInfo.shipmentId
      );
      setOrdersToShip(ordersToShip);
    }
  }, [group]);

  return (
    <>
      <HeaderColumn group={group} now={now}>
        <span>
          <strong>{group.name}</strong> ({group.cards.length})
        </span>
        {ordersToShip.length && ordersToShip[0].orderType !== "pickup" ? (
          <Button variant="warning" size="sm" onClick={handleCreateOfferClick}>
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Crear oferta"
            )}
          </Button>
        ) : (
          <></>
        )}
      </HeaderColumn>
    </>
  );
};

const HeaderColumn = styled.div`
  font-size: 16px;
  ${({ group: { startHour, endHour }, now }) => {
    if (now >= startHour - 1 && now < endHour - 1) {
      return `
        color: black;
        font-weight: bold;
      `;
    } else {
      return `
        color: black;
        font-weight: normal;
      `;
    }
  }}

  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export default OrderRenderHeader;
